<template>
  <div class="card" style="border: none" :id="isPrint ? 'print' : ''">
    <div class="card-body" :style="isPrint ? `height: ${height};` : ''">
      <div class="row justify-content-between align-items-center">
        <div class="col-8">
          <table>
            <div style="font-size: 14pt"><strong>Kwitansi</strong></div>
            <tr>
              <td style="width: 100px">Nomor</td>
              <td style="width: 9px">:</td>
              <td>{{ data.id }}</td>
            </tr>
            <tr>
              <td>Tanggal</td>
              <td style="width: 9px">:</td>
              <td>{{ payment_slip.display_date }}</td>
            </tr>
            <tr>
              <td>Nama Pasien</td>
              <td style="width: 9px">:</td>
              <td>{{ data.patient_name }}</td>
            </tr>
            <tr v-if="data.sub_patient_id != null">
              <td>Nama Sub Pasien</td>
              <td style="width: 9px"> : </td>
              <td> {{ data.sub_patient_name }}</td>
            </tr>
            <tr>
              <td>Pembayaran</td>
              <td style="width: 9px">:</td>
              <td>#{{ $route.query.terms }}</td>
            </tr>
          </table>
        </div>
        <div class="col">
          <ImageComponent place="logo-invoice"></ImageComponent>
          <div class="font-weight-light" style="font-size: 10.7px">
            {{ config.clinic_address }}
          </div>
          <div class="font-weight-light" style="font-size: 10.7px">
            {{ config.phone }}
          </div>
        </div>
      </div>
      <div style="min-height: 190px">
        <div class="row">
          <div class="col-md-12">
            <b-table striped hover small :items="items" :fields="fields">
              <template #table-colgroup="scope">
                <col v-for="field in scope.fields" :key="field.key" />
              </template>
              <template #cell(price)="data">
                {{
                  "Rp " + parseInt(parseInt(data.item.price) - parseInt(data.item.promo_amount)
                    + data.item.discount_amount).toLocaleString("id-ID")
                }}
              </template>
              <template #cell(quantity)="data">
                {{ data.item.quantity }}
              </template>
              <template #cell(discount)="data">
                <span>Rp
                  {{
                    `${parseInt(data.item.discount_amount).toLocaleString(
                      "id-ID"
                    )} ${data.item.discount_type == 1 &&
                      (data.item.discount_amount != 0 ||
                        data.item.discount_percentage != 0)
                      ? `(${parseInt(data.item.discount_percentage)})%`
                      : ""
                    }`
                  }}</span>
              </template>
              <template #cell(subtotal)="data">
                {{ 'Rp ' + parseInt(data.item.total).toLocaleString('id-ID') }}
              </template>
            </b-table>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 py-0">
            <span class="font-weight-bold d-block">Catatan :</span>
          </div>
          <div class="col-md-12 pt-1">
            <div
              style="width: 300px;height: 90px;border: 2px solid rgb(196, 196, 196);border-radius: 7px;padding: 5px;overflow: hidden;">
              <span>{{ data.notes }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-top">
        <div class="mr-2">
          <!-- Notes -->
          <!-- <div class="form-group" v-if="renderNotes">
            <label for="notes">Catatan:</label>
            <textarea
              name=""
              id="notes"
              cols="30"
              rows="3"
              class="form-control"
              style="resize: none"
              v-model="payment_slip.notes"
              readonly
            ></textarea>
          </div> -->
        </div>
        <table v-if="showSummary">
          <tr>
            <td style="width: 150px">Subtotal</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.total_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Diskon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.discount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Biaya Tambahan</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.surcharge).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr v-if="data.coupon_amount">
            <td>Kupon</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">
                {{ parseInt(data.coupon_amount).toLocaleString('id-ID') }}
              </span>
            </td>
          </tr>
          <tr>
            <td>Total</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(data.final_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>
              <hr style="margin: 5px" />
            </td>
            <td>
              <hr style="margin: 5px" />
            </td>
            <td>
              <hr style="margin: 5px" />
            </td>
          </tr>
          <tr>
            <td>Tagihan</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.bill_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td>Terbayar</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.payment_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr v-if="payment_slip.change_amount > 0">
            <td>Kembalian</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.change_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td class="text-danger">Sisa</td>
            <td class="font-weight-bold" style="width: 25px">Rp</td>
            <td class="text-right text-danger">
              <span class="font-weight-bold">{{
                parseInt(payment_slip.unpaid_amount).toLocaleString("id-ID")
              }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 120px; padding: 1.3px">Metode Bayar</td>
            <td class="font-weight-bold" style="width: 25px"></td>
            <td class="text-right" v-if="payment_slip.payment_method_id == 2">
              {{ `${cardType} ${payment_slip.bank_name}` }}
            </td>
            <td class="text-right" v-if="payment_slip.payment_method_id != 2">
              {{
                `${payment_slip.payment_method_name} ${payment_slip.payment_method_id == 3
                  ? payment_slip.bank_account_name
                  : ""
                }`
              }}
            </td>
          </tr>
          <tr v-if="payment_slip.payment_status == 3">
            <td colspan="3" class="text-center font-weight-bold">*LUNAS*</td>
          </tr>
        </table>
      </div>

      <div class="d-flex justify-content-center ">
        <div>
          <hr class="special-hr" style="margin: 2px" />
          <div>{{ currentUserPersonalInfo.name }}</div>
        </div>
      </div>

      <div class="d-flex justify-content-start pl-2 pb-2 mt-4">
        <div>
          <span>
            Note : all transaction is non-refundable
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ImageComponent from "@/component/general/ImageComponent.vue";
import {
  setConfiguration,
  getConfiguration,
} from "@/core/services/jwt.service.js";

export default {
  components: {
    ImageComponent,
  },

  props: {
    data: Object,
    setting: Object,
    height: String,
    isPrint: Boolean,
    items: Array,
    renderNotes: Boolean,
    payment_slip: Object,
    showSummary: Boolean,
  },

  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    mutated_total_amount: {
      set(newValue) {
        this.data.total_amount = newValue;
      },
      get() {
        let amount = this.data.total_amount;
        this.items.forEach((value) => {
          amount += value.discount_amount * value.quantity;
        });
        return amount;
      },
    },

    mutated_final_amount: {
      set(newValue) {
        this.data.final_amount = newValue;
      },
      get() {
        let amount = this.data.final_amount;
        this.items.forEach((value) => {
          amount += value.discount_amount * value.quantity;
        });
        return amount;
      },
    },
  },

  data() {
    return {
      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "name",
          label: "Keterangan",
        },
        {
          key: "quantity",
          label: "Jumlah",
          thStyle: { width: '5%' }
        },
        {
          key: "discount",
          label: "Diskon",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "price",
          label: "Harga",
          thClass: "text-right",
          tdClass: "text-right",
        },
        {
          key: "subtotal",
          label: "Subtotal",
          thClass: "text-right",
          tdClass: "text-right",
        },
      ],
      config: getConfiguration() != null ? getConfiguration() : "",
      cardType: "",
    };
  },

  methods: {
    async get() {
      this.config = await module.get("settings-no-auth/1");
    },
    async getSetting() {
      let response = await module.get("settings-no-auth/1");
      if (response != null) {
        setConfiguration(JSON.stringify(response));
        this.config = response;
      }
    },
  },

  mounted() {
    if (this.config == "") {
      this.getSetting();
    }

    this.$root.$on("configurationEditSuccessfully", () => {
      this.get();
    });
  },

  watch: {
    payment_slip: function (newVal) {
      if (this.payment_slip.card_type == 1) {
        this.cardType = "K. Kredit";
      } else if (this.payment_slip.card_type == 2) {
        this.cardType = "K. Debit";
      }
    },
  },
};
</script>

<style scoped>
.special-hr {
  border-color: #000000;
  border-style: solid;
  border-width: 2px 0 0;
  clear: both;
  margin: 0 0 0px;
  height: 0;
}
</style>
